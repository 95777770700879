body{
background: #0F2027;
  color:#fff;
}



.content, .gotten{
  background-color: #2C5364;

  display: flex;
  flex-direction: column;
  align-items: center;
 text-align: center;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 width: 80%;
 margin: auto;
 margin-top:10rem;
 padding: 1rem;
 padding-bottom:3rem;

border-radius: 15px;
}
h1{
  font-size: 1.6rem;
}
.btn{
  padding:10px 20px;
 background-color: #fff;
 color:#333;
 margin-top:1rem;
}

a{
  text-decoration: none;
  color:#000;
}

.emailas{
  text-align: center;
  font-size: 1.3rem;
}


.loadingbckg{
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #000;
  opacity: 0.9;
}

.lds-ripple {
  display: inline-block;
  position: absolute;
  left:40%;
  top:40%;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

#video{
  position: absolute;
  top:0 !important;
  bottom: 0 !important;
  height: auto;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


.reader{
  padding-top: 9rem;
  height: 100vh;
  background-color: #000;
  opacity: 0.8;
}
.authentication{
  display: flex;
  flex-direction: column;
  align-items: center;
 margin-top:10rem;
}

input{
  background-color: transparent;
  border: solid 2px #fff;
  padding:0.5rem 1rem;
  color:#fff;
}


.list{
  color:#fff;
}

.listitem{
  padding: 1rem;
 border-bottom: solid 1px #fff;
}

.back{
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-left: 1rem;
}

.btnlist{
  width: fit-content;
  margin: auto;
  margin-top:5rem;
  color:#fff !important;
  font-size: 1.5rem;
  border-bottom: solid 1px #FFF;;
}